import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const Filter = ({
  filterTransaction,
  onChangeFilterTransaction,
  onResetFilter,
  listKiosk
}) => {
  return (
    <div>
    <div className="card-toolbar my-1 d-flex align-items-center justify-content-center filter-container">
      <div className="me-5 my-1 d-flex align-items-center filter-item">
        <span style={{ fontSize: '12px', marginRight: 10 }}>
          Tên máy:
        </span>
        <select
          id="kt_filter_orders"
          name="orders"
          data-control="select2"
          data-hide-search="true"
          className="w-125px form-select form-select-solid form-select-sm"
          value={filterTransaction.kioskId}
          onChange={(event) =>
            onChangeFilterTransaction({
              kioskId: event.target.value
            })
          }
        >
          <option value="" selected="selected">
            Tất cả
          </option>
          {listKiosk.map((kiosk) => (
            <option key={kiosk.kioskId} value={kiosk.kioskId}>{kiosk.kioskName}</option>
          ))}
        </select>
      </div>

      <div className="me-5 my-1 d-flex align-items-center filter-item">
        <span style={{ fontSize: '12px', marginRight: 10 }}>
          Số ô:
        </span>
        <select
          id="kt_filter_orders"
          name="orders"
          data-control="select2"
          data-hide-search="true"
          className="w-125px form-select form-select-solid form-select-sm"
          value={filterTransaction.licensePlate}
          onChange={(event) =>
            onChangeFilterTransaction({
              licensePlate: event.target.value
            })
          }
        >
          <option value="" selected="selected">
            Tất cả
            </option>
            {[...Array(36)].map((_, i) => (
              <option key={i+1} value={i+1}>
                {i+1}
              </option>
            ))}
        </select>
      </div>

      <div className="me-4 my-1 d-flex align-items-center filter-item">
        <span style={{ fontSize: '12px', marginRight: 10 }}>
          Phương thức thanh toán:
        </span>

        <select
          id="kt_filter_orders"
          name="orders"
          data-control="select2"
          data-hide-search="true"
          className="w-125px form-select form-select-solid form-select-sm"
          value={filterTransaction.paymentMethod}
          onChange={(event) =>
            onChangeFilterTransaction({
              paymentMethod: event.target.value
            })
          }
        >
          <option value="" selected="selected">
            Tất cả
          </option>
          <option value="NONE">NONE</option>
          <option value="FREE">FREE</option>
          <option value="CASH">TIỀN MẶT</option>
          <option value="VNPAY">VNPAY</option>
          <option value="MPOS">MPOS</option>
          <option value="MOMO">MOMO</option>
        </select>
      </div>

    </div>
    <div className="card-toolbar my-1 d-flex align-items-right justify-content-right filter-container">
      
  

      <div className="me-4 my-1 d-flex align-items-center filter-item">
        <span style={{ fontSize: '12px', marginRight: 10 }}>
          Ngày gửi từ:
        </span>
        <DatePicker
          className="w-125px ms-8"
          dateFormat="dd/MM/yyyy"
          selected={filterTransaction.startDate}
          onChange={(date) =>
            onChangeFilterTransaction({
              startDate: date
            })
          }
        />
      </div>
      <div className="me-4 my-1 d-flex align-items-center filter-item">
        <span style={{ fontSize: '12px', marginRight: 10 }}>
          đến:
        </span>
        <DatePicker
          className="w-125px ms-2"
          dateFormat="dd/MM/yyyy"
          selected={filterTransaction.endDate}
          onChange={(date) =>
            onChangeFilterTransaction({
              endDate: date
            })
          }
        />
      </div>  

      <img
        onClick={onResetFilter}
        className="cursor-pointer filter-reset"
        src="/assets/images/reset.png"
        alt="lisence-plate"
        width={30}
      />
    </div>

  </div>  
  )
}

export default Filter

